import { lighten } from '@mui/material'

import MuiButton from '@mui/material/Button'
import type { MouseEventHandler, ReactNode } from 'react'
import { Spinner } from '@shared/components/base/Spinner'

type BaseButtonProps = {
    isLoading?: boolean
    variant?: 'contained' | 'outlined' | 'text'
    size?: 'small' | 'medium' | 'large'
    children: ReactNode
    disabled?: boolean
    color?: 'primary' | 'secondary' | 'inherit'
    onClick?: MouseEventHandler<HTMLButtonElement>
    type?: 'submit' | 'button' | 'reset'
    className?: string
    startIcon?: ReactNode
}

export const sizesMap = {
    small: 40,
    medium: 48,
    large: 56,
}

export const Button = (props: BaseButtonProps) => {
    const {
        isLoading,
        children,
        variant = 'contained' as const,
        size = 'small' as const,
        color = 'primary',
        ...rest
    } = props
    const height = sizesMap[size]

    return (
        <MuiButton
            fullWidth
            variant={variant}
            color={color}
            {...rest}
            sx={(theme) => {
                return {
                    fontSize: 16,
                    textTransform: 'none',
                    boxShadow:
                        variant === 'contained' && color === 'primary'
                            ? `0px 2px 5px 0px ${lighten(theme.palette.primary.main, 0.7)}`
                            : variant === 'contained' && color === 'secondary'
                              ? `0px 2px 5px 0px #00000040`
                              : '',
                    ':hover': {
                        color: variant === 'text' || variant === 'outlined' ? theme.palette.primary.dark : '',
                        borderColor: variant === 'outlined' ? theme.palette.primary.dark : '',
                        backgroundColor:
                            variant === 'contained' && color === 'secondary'
                                ? theme.palette.secondary.dark
                                : variant === 'contained' && color === 'primary'
                                  ? theme.palette.primary.dark
                                  : 'transparent',
                    },
                    height,
                }
            }}
        >
            {isLoading ? <Spinner color="inherit" /> : children}
        </MuiButton>
    )
}
