import { ReactElement, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router'

import { AppBar } from './AppBar'
import { Sidebar } from './Sidebar'
import { SidebarMobile } from './SidebarMobile'
import { useIsTablet } from '@shared/hooks/useBreakpoint'
import { Spinner } from '@shared/components/base/Spinner'

export function PageLayout() {
    const isTablet = useIsTablet()
    const [isSidebarOpen, setIsSidebarOpen] = useState(!isTablet)

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    const sidebarWidth = isSidebarOpen ? 'var(--side-bar-width-open)' : 'var(--side-bar-width-closed)'

    useEffect(() => {
        if (isTablet) setIsSidebarOpen(false)
    }, [isTablet])

    return (
        <div className="w-full">
            <AppBar toggleSidebar={toggleSidebar} />

            <div className="flex h-full" style={{ marginTop: 'var(--app-bar-height)' }}>
                {isTablet ? (
                    <SidebarMobile isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                ) : (
                    <Sidebar isSidebarOpen={isSidebarOpen} />
                )}
                <ScrollToTopWrapper>
                    <div className="w-full px-md" style={{ marginLeft: isTablet ? 0 : sidebarWidth }}>
                        <Suspense
                            fallback={
                                <div
                                    className="grid place-items-center"
                                    style={{ height: 'calc(100vh - var(--app-bar-height))' }}
                                >
                                    <Spinner />
                                </div>
                            }
                        >
                            <Outlet />
                        </Suspense>
                    </div>
                </ScrollToTopWrapper>
            </div>
        </div>
    )
}

function ScrollToTopWrapper({ children }: { children: ReactElement }) {
    const location = useLocation()

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    }, [location.pathname])

    return children
}
