import axios from 'axios'
import {
    accessCookieName,
    expirationDateCookieName,
    getCookie,
    refreshCookieName,
    setCookie,
} from '@shared/helpers/cookies'
import _set from 'lodash/set'

export const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: Number(import.meta.env.VITE_API_TIMEOUT),
    headers: {
        'Content-Type': 'application/json',
    },
})

export const handlers = {
    logout: () => {},
}

axiosInstance.interceptors.request.use((config) => {
    const token = getCookie(accessCookieName)
    if (!config.headers?.skipAuth && token) _set(config, 'headers.Authorization', `Bearer ${token}`)

    return config
})

axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
        const originalRequest = error.config
        if (!originalRequest) return Promise.reject(error)

        const status = error.response?.status || 0

        if ([401, 403].includes(status) && !originalRequest._retry) {
            originalRequest._retry = true // Mark the request as retried to avoid infinite loops.

            const refreshToken = getCookie(refreshCookieName)

            if (!refreshToken) {
                handlers.logout()
                return Promise.reject(error)
            }

            try {
                const refreshRequest = (await axiosInstance.post(
                    '/token-refresh',
                    { refreshToken },
                    { headers: { skipAuth: true } }
                )) as { data: { token: string } }

                const token = refreshRequest.data.token
                const expirationDateValue = getCookie(expirationDateCookieName)
                    ? Number.parseInt(getCookie(expirationDateCookieName))
                    : undefined
                setCookie(accessCookieName, token, expirationDateValue)

                return axiosInstance(originalRequest)
            } catch (e) {
                handlers.logout()
            }
        }

        return Promise.reject(error)
    }
)
