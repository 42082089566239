import { setupWorker } from 'msw/browser'
import { handlers } from './devHandlers'
import { isDevelopment } from '@shared/helpers/utils'

export const devServer = async () => {
    if (isDevelopment()) {
        const server = setupWorker(...handlers)

        return server.start({
            quiet: false,
            onUnhandledRequest: 'bypass',
            serviceWorker: {
                // Points to the custom location of the Service Worker file.
                url: '/mockServiceWorker.js',
            },
        })
    }
}
