import { CircularProgress } from '@mui/material'
import type { ReactElement } from 'react'

const sizesMap = {
    small: 25,
    medium: 50,
    large: 75,
    xlarge: 100,
}

export type SpinnerProps = {
    size?: keyof typeof sizesMap
    color?: 'primary' | 'secondary' | 'inherit'
}

export function Spinner(props: SpinnerProps): ReactElement {
    const { size = 'small', color = 'primary' } = props
    const l = sizesMap[size]

    return <CircularProgress color={color} size={l} />
}
