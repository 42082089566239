import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router'
import { Tooltip } from '@mui/material'

import { cn } from '@shared/helpers/utils'
import { sidebarItems } from '../constants'

export function Sidebar({ isSidebarOpen }: { isSidebarOpen: boolean }) {
    const { t } = useTranslation()
    const location = useLocation()

    const sidebarWidth = isSidebarOpen ? 'var(--side-bar-width-open)' : 'var(--side-bar-width-closed)'

    return (
        <div className="fixed" style={{ width: sidebarWidth }}>
            <div className="flex size-full h-screen flex-col items-center gap-md bg-white pt-lg shadow-md">
                {sidebarItems.map((item) => {
                    const { label, route, icon } = item
                    return (
                        <Tooltip
                            title={isSidebarOpen ? '' : <span className="capitalize">{label}</span>}
                            arrow
                            placement="right"
                            key={label}
                        >
                            <Link
                                to={route}
                                className={cn(
                                    'flex h-[48px] items-center gap-sm rounded-xl border px-sm capitalize text-black no-underline',
                                    {
                                        'bg-primary text-white': location.pathname.startsWith(route),
                                        'w-3/4': isSidebarOpen,
                                    },
                                    {
                                        'hover:bg-secondary': !location.pathname.startsWith(route),
                                    }
                                )}
                            >
                                <span className="size-6 cursor-pointer">{icon}</span>

                                {isSidebarOpen && <span>{t(label)}</span>}
                            </Link>
                        </Tooltip>
                    )
                })}
            </div>
        </div>
    )
}
