import _get from 'lodash/get'
import axios, { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import MuiSnackbar from '@mui/material/Snackbar'
import { Alert } from '@shared/components/base/Alert'
import { IconButton } from '@shared/components/base/IconButton'
import { createContext, ReactElement, useContext, useState } from 'react'
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'

interface State {
    open: boolean
    body: string
    severity: 'success' | 'info' | 'warning' | 'error'
}

interface SnackbarContextProps {
    openWarningSnackbar: (body: string) => void
    openInfoSnackbar: (body: string) => void
    openSuccessSnackbar: (body: string) => void
    openErrorSnackbar: (error: string | AxiosError) => void
}

/* Context */
const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined)
SnackbarContext.displayName = 'SnackbarContext'

/* Provider */
function SnackbarProvider(props: { children: ReactElement | ReactElement[]; autoHideDuration?: number }): ReactElement {
    const { t } = useTranslation()
    const { children, autoHideDuration = 3000 } = props
    const [state, setState] = useState<State>({
        open: false,
        body: '',
        severity: 'info',
    })

    const openSnackbar = (props: Omit<State, 'open'>) => setState({ ...props, open: true })
    const closeSnackbar = () => setState((prevState) => ({ ...prevState, open: false }))

    const openWarningSnackbar = (body: string) => openSnackbar({ severity: 'warning', body })
    const openInfoSnackbar = (body: string) => openSnackbar({ severity: 'info', body })
    const openSuccessSnackbar = (body: string) => openSnackbar({ severity: 'success', body })
    const openErrorSnackbar = (error: string | AxiosError) => {
        openSnackbar({
            severity: 'error',
            body: axios.isAxiosError(error)
                ? _get(error, 'response.data.message', t('somethingWentWrongPleaseTryAgainLater'))
                : error,
        })
    }

    return (
        <SnackbarContext.Provider
            value={{ openErrorSnackbar, openSuccessSnackbar, openWarningSnackbar, openInfoSnackbar }}
        >
            {children}
            <MuiSnackbar
                open={state.open}
                onClose={closeSnackbar}
                autoHideDuration={autoHideDuration}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={state.severity || 'info'}
                    icon={
                        state.severity === 'success' ? (
                            <CheckCircleIcon className="size-6" />
                        ) : state.severity === 'error' ? (
                            <XCircleIcon className="size-6" />
                        ) : undefined
                    }
                    action={
                        <IconButton size="small" color="inherit" onClick={closeSnackbar}>
                            <XMarkIcon className="size-4" />
                        </IconButton>
                    }
                    description={state.body}
                />
            </MuiSnackbar>
        </SnackbarContext.Provider>
    )
}

/* useContext */
function useSnackbar() {
    const context = useContext(SnackbarContext)
    if (context === undefined) throw new Error(`SnackbarContext must be used within a SnackbarProvider`)

    return context
}

export { SnackbarProvider, useSnackbar }
