import Cookies from 'universal-cookie'
import { isDevelopment } from '@shared/helpers/utils'

const cookies = new Cookies()

/*
	These names are used in the final profile repo,
	if you want to change them here, consider changing
	them there too
*/
const cookiePrefix = '_365portal'
export const accessCookieName = `${cookiePrefix}_access`
export const refreshCookieName = `${cookiePrefix}_refresh`
export const expirationDateCookieName = `${cookiePrefix}_expirationDate`

export function setCookie(cookieName: string, value: string, expiresAtInSeconds?: number): void {
    cookies.set(cookieName, value, {
        path: '/',
        sameSite: 'strict',
        expires: expiresAtInSeconds ? new Date(expiresAtInSeconds * 1000) : undefined,
        secure: !isDevelopment(),
    })
}

export function getCookie(cookieName: string): string {
    return cookies.get(cookieName)
}

export function removeCookie(CookieName: string): void {
    cookies.remove(CookieName)
}
