import MuiAvatar from '@mui/material/Avatar'
import { cn } from '@shared/helpers/utils'

interface Props {
    src?: string
    fullName?: string
    size?: number
    className?: string
}

export default function Avatar(props: Props) {
    const { src, fullName, className, size = 40 } = props

    const fullNameParts = fullName?.split(' ')

    const initials = fullNameParts ? `${fullNameParts[0]?.[0] || ''}${fullNameParts[1]?.[0] || ''}` : null

    return (
        <MuiAvatar
            alt={fullName}
            src={src}
            sx={{ width: size, height: size, fontSize: size / 2.5 }}
            className={cn('', className)}
        >
            {initials}
        </MuiAvatar>
    )
}
