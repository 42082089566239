import { Spinner } from '@shared/components/base/Spinner'
import { type ReactElement, Suspense, lazy } from 'react'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate, Outlet } from 'react-router'
import { PageLayout } from './features/layout/ui/PageLayout'
import { PrivateRoute } from './features/authentication/ui/PrivateRoute'
import { GuestRoute } from './features/authentication/ui/GuestRoute'
import { ScreenCentered } from './features/layout/ui/ScreenCentered'
import { ExternalPreviewTemplate } from './features/accessories/ui/edit/ExternalPreviewTemplate'

const LoginPage = lazy(() => import('./features/login/ui/LoginPage'))
const OauthCallbackPage = lazy(() => import('./features/login/ui/OauthCallbackPage'))
const LoginWithWebApp = lazy(() => import('./features/login/ui/LoginWithWebApp'))
const ProfilesPage = lazy(() => import('./features/profiles/ui/ProfilesPage'))
const EditProfilePage = lazy(() => import('./features/profiles/ui/EditProfilePage'))
const SettingsPage = lazy(() => import('./features/settings/ui/SettingsPage'))
const DashboardPage = lazy(() => import('./features/dashboard/ui/DashboardPage'))
const AccessoriesPage = lazy(() => import('./features/accessories/ui/AccessoriesPage'))
const ConnectionsPage = lazy(() => import('./features/connections/ui/ConnectionsPage'))
const ActivatePage = lazy(() => import('./features/activate/ui/ActivatePage'))
const ActivationSuccessView = lazy(() => import('./features/activate/ui/ActivationSuccessView'))
const ActivationOwnerSuccessView = lazy(() => import('./features/activate/ui/ActivationOwnerSuccessView'))
const ActivationErrorView = lazy(() => import('./features/activate/ui/ActivationErrorView'))
const NotFoundPage = lazy(() => import('@shared/components/NotFoundPage'))
const ProfilePreviewPage = lazy(() => import('./features/profile-preview/ui/ProfilePreviewPage'))
const CredentialsForm = lazy(() => import('./features/login/ui/CredentialsForm'))
const OtpFlow = lazy(() => import('./features/login/ui/OtpFlow'))
const EditSingleAccessoryPage = lazy(() => import('./features/accessories/ui/edit/EditSingleAccessoryPage'))
const EditBulkAccessoryPage = lazy(() => import('./features/accessories/ui/edit/EditBulkAccessoriesPage'))

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={'/'}>
            <Route path={'/login'} element={<GuestRoute element={<LoginPage />} />}>
                <Route path={''} element={<OtpFlow />} />
                <Route path={'credentials'} element={<CredentialsForm />} />
            </Route>
            <Route path={'/oauthCallback'} element={<GuestRoute element={<OauthCallbackPage />} />} />
            <Route path={'/login-with-webapp'} element={<LoginWithWebApp />} />
            <Route path={'/card/:slug'} element={<ActivatePage />} />
            <Route path={'/card/:slug/success'} element={<ActivationSuccessView />} />
            <Route path={'/card/:slug/owner-success'} element={<ActivationOwnerSuccessView />} />
            <Route path={'/card/:slug/error'} element={<ActivationErrorView />} />

            <Route path={'/preview/:slug'} element={<PrivateRoute element={<ProfilePreviewPage />} />} />

            <Route path={'/profile/template/:id'} element={<PrivateRoute element={<ExternalPreviewTemplate />} />} />

            <Route path={'/'} element={<PrivateRoute element={<PageLayout />} />}>
                <Route index element={<Navigate to="/accessories" />} />
                <Route path={'/accessories'} element={<Outlet />}>
                    <Route index element={<AccessoriesPage />} />
                    <Route path={'activation/:id/edit'} element={<EditSingleAccessoryPage />} />
                    <Route path={'activation/bulk-edit'} element={<EditBulkAccessoryPage />} />
                </Route>
                <Route path={'/profiles'} element={<Outlet />}>
                    <Route index element={<ProfilesPage />} />
                    <Route path={':id/edit'} element={<EditProfilePage />} />
                </Route>
                <Route path={'/settings'} element={<SettingsPage />} />
                <Route path={'/dashboard'} element={<DashboardPage />} />
                <Route path={'/connections'} element={<ConnectionsPage />} />
                {/* include the not-found route in the private routes to prevent revealing what
                 pages do we have, since the visitor will have to log in first to know if a page exits or not */}
                <Route path={'/*'} element={<NotFoundPage />} />
            </Route>
        </Route>
    ),
    { basename: `${import.meta.env.BASE_URL}` }
)

export function AppRoutes(): ReactElement {
    return (
        <Suspense
            fallback={
                <ScreenCentered>
                    <Spinner />
                </ScreenCentered>
            }
        >
            <RouterProvider router={router} />
        </Suspense>
    )
}
