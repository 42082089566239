import { PathBuilder } from '@scr2em/path-builder'

// this object should match the structure of the files tree
export const publicFolder = PathBuilder(
    {
        images: {
            logoColorCaptello: 'logo-color-captello.png',
            logoImageOnly: 'captello.com.ico',
            profileCoverPlaceholder: 'profile-cover-placeholder.png',
            profileAvatarPlaceholder: 'profile-avatar-placeholder.png',
            card: 'card.png',
            button: 'button.png',
            sticker: 'sticker.png',
            bracelet: 'bracelet.png',
            bracelet2: 'bracelet2.png',
            braceletTransparentBg: 'bracelet-transparentBg.png',
            login: 'login.svg',
            loginBg: 'loginBg.png',
            googleIcon: 'googleIcon.png',
            letsGetStarted: 'lets-get-started.svg',
            success: 'success.svg',
            lock: 'lock.svg',
            welcomeOwnerBg: 'welcomeOwnerBg.png',
            welcomeOwnerBgMobile: 'welcomeOwnerBgMobile.png',
            somethingWentWrong: 'somethingWentWrong.svg',
            avatar: 'avatar.svg',
            shareViaEmail: 'share-via-email.svg',
            shareViaLinkedin: 'share-via-linkedin.svg',
            shareViaX: 'share-via-x.svg',
            shareViaWhatsapp: 'share-via-whatsapp.svg',
            noData: {
                accessories: 'accessories.svg',
                noConnections: 'noConnections.svg',
                emptyBox: 'empty-box.svg',
                profiles: 'profiles.svg',
                serverError: 'server-error.svg',
                noResults: 'no-results.svg',
            },
        },
    },
    ''
)
