import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function extractInitialsFromFullName(fullName: string): string {
    if (!fullName) return ''

    // Split the full name by spaces
    const nameParts = fullName.trim().split(/\s+/)

    // Map each part to its first letter and join them
    const initials = nameParts.map((part) => part[0]?.toUpperCase()).join('')

    return initials
}

export function extractInitialsFromFirstNameAndLastName(firstName: string, lastName: string): string {
    const firstInitial = firstName[0] ? firstName[0].toUpperCase() : ''
    const lastInitial = lastName[0] ? lastName[0].toUpperCase() : ''

    return `${firstInitial}${lastInitial}`
}

export const isProduction = (): boolean => import.meta.env.PROD
export const isDevelopment = (): boolean => import.meta.env.DEV

export function cn(...inputs: (ClassValue | undefined)[]) {
    return twMerge(clsx(inputs))
}

export const absoluteUrl = (): {
    protocol: string
    host?: string
    origin: string
} => {
    const parsedUrl = new URL(window.location.href)

    return {
        protocol: parsedUrl.protocol,
        host: parsedUrl.host,
        origin: parsedUrl.origin,
    }
}

export const prependApiUrl = (path: string): string => {
    return import.meta.env.VITE_API_URL + path
}

export function getFullName(firstName: string, lastName: string) {
    return lastName ? `${firstName} ${lastName}` : firstName
}

export function getNameFromEmail(email: string): string {
    if (email && email.includes('@')) {
        return email.split('@')[0] || ''
    }

    return ''
}

export async function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export function getHost() {
    const location = absoluteUrl()
    const host = location.host
    return isDevelopment() ? 'staging-365.captello.com' : host
}

export function setBrowserTitle(title: string): void {
    let titleElm = document.querySelector<HTMLTitleElement>('title')

    if (!titleElm) {
        titleElm = document.createElement('title')
        document.head.appendChild(titleElm)
    }

    titleElm.innerText = title
}

export async function getBase64Image(url: string): Promise<string | null> {
    try {
        const response = await fetch(url)

        if (!response.ok) return null

        const blob = await response.blob()

        // Wrap FileReader in a Promise to handle its asynchronous nature
        return await new Promise<string | null>((resolve, reject) => {
            const reader = new FileReader()

            reader.onloadend = () => {
                const result = reader.result as string
                resolve(result.split(',')[1] || null)
            }

            reader.onerror = () => reject(null)

            reader.readAsDataURL(blob)
        })
    } catch {
        return null
    }
}

export function getFinalProfileBaseUrl() {
    const isLocalhost = location.host.includes('localhost')

    // assuming you are opening the final profile locally at localhost:3000
    return isLocalhost ? 'http://localhost:3000' : location.origin
}
