import type { SxProps } from '@mui/material'
import MuiIconButton from '@mui/material/IconButton'
import type { MouseEventHandler, ReactElement } from 'react'

type IconButtonProps = {
    children: ReactElement
    disableRipple?: boolean
    disabled?: boolean
    size?: 'small' | 'medium' | 'large'
    variant?: 'filled' | 'clear'
    sx?: SxProps
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined
    color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
}

const variantsStyles = {
    filled: { bgcolor: '#E0E0E0' },
    clear: {},
}
export function IconButton(props: IconButtonProps): ReactElement {
    const { variant = 'clear', sx, disableRipple, disabled, size, onClick, color, children } = props

    const baseStyles = variantsStyles[variant]

    return (
        <MuiIconButton
            sx={{ ...baseStyles, ...sx }}
            disableRipple={disableRipple}
            disabled={disabled}
            size={size}
            color={color}
            onClick={onClick}
        >
            {children}
        </MuiIconButton>
    )
}
