import { useAuth } from './AuthContainer'
import { Navigate, useSearchParams } from 'react-router'

interface Props {
    element: JSX.Element
}
/*
 * GuestRoute is a route that only allows unauthenticated users to access it.
 */
export function GuestRoute({ element }: Props): JSX.Element | null {
    const [searchParams] = useSearchParams()
    const { isAuthenticated } = useAuth()
    const redirectUrl = searchParams.get('redirect')
    const relativePath = redirectUrl ? redirectUrl : '/'

    return isAuthenticated ? <Navigate to={relativePath} /> : element
}
