import { Breakpoint, useMediaQuery, useTheme } from '@mui/material'

function useBreakpoint(breakpoint: Breakpoint) {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.down(breakpoint))
}

export function useIsMobile(): boolean {
    return useBreakpoint('sm')
}

export function useIsTablet(): boolean {
    return useBreakpoint('md')
}
