import { forwardRef, ElementRef, ComponentPropsWithoutRef } from 'react'
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
} from '@mui/material'
import { cn } from '@shared/helpers/utils'

export const Dialog = forwardRef<ElementRef<typeof MuiDialog>, ComponentPropsWithoutRef<typeof MuiDialog>>(
    ({ className, ...props }, ref) => {
        return <MuiDialog ref={ref} className={cn('', className)} {...props} />
    }
)
Dialog.displayName = 'Dialog'

export const DialogTitle = forwardRef<
    ElementRef<typeof MuiDialogTitle>,
    ComponentPropsWithoutRef<typeof MuiDialogTitle>
>(({ className, ...props }, ref) => {
    return <MuiDialogTitle ref={ref} className={cn('', className)} {...props} />
})
DialogTitle.displayName = 'DialogTitle'

export const DialogContent = forwardRef<
    ElementRef<typeof MuiDialogContent>,
    ComponentPropsWithoutRef<typeof MuiDialogContent>
>(({ className, ...props }, ref) => {
    return <MuiDialogContent ref={ref} className={cn('', className)} {...props} />
})
DialogContent.displayName = 'DialogContent'

export const DialogActions = forwardRef<
    ElementRef<typeof MuiDialogActions>,
    ComponentPropsWithoutRef<typeof MuiDialogActions>
>(({ className, ...props }, ref) => {
    return <MuiDialogActions ref={ref} className={cn('', className)} {...props} />
})
DialogActions.displayName = 'DialogActions'
